import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import logo from '../../Images/PR_logo.png'; // Make sure the path is correct
import '../../Styles/navbar.css'; // Import your CSS file
import { Link } from 'react-router-dom';
import Header from '../Header'; // Import the Header component

import { useMediaQuery } from 'react-responsive';

function NavigationBar() {
  const isXs = useMediaQuery({ query: '(max-width: 575px)' });
  const isSm = useMediaQuery({ query: '(max-width: 767px)' });
  const isMd = useMediaQuery({ query: '(max-width: 991px)' });
  const isLg = useMediaQuery({ query: '(max-width: 1199px)' });
  const screenSize = isXs ? 'xs' : isSm ? 'sm' : isMd ? 'md' : isLg ? 'lg' : 'xl';

  return (
    <>
      <Header /> {/* Render the Header component */}
      <Navbar data-bs-theme="light" expand="lg" className={`bg-body-tertiary custom-navbar navbar-${screenSize}-down`}>
        <Container>
          <Navbar.Brand href="/Home">
            <img src={logo} alt='mylogo' width="80" height="80" className="d-inline-block align-top" />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Link to="/Home">
                <Nav.Link as="span" className="nav-link">
                  Home
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="/Rentals">
                <Nav.Link as="span" className="nav-link">
                  Rentals
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="/About">
                <Nav.Link as="span" className="nav-link">
                  About
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="/Contact">
                <Nav.Link as="span" className="nav-link">
                  Contact
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
            </Nav>
            <Link to="/QuoteForm">
              <Button variant="warning">Book Now</Button>
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
