import React from 'react'
import ProjectsGrid from '../ProjectsGrid';
import { Breadcrumb } from 'react-bootstrap'
import '../../Styles/page_rentals.css'

function Rentals() {
  return (
    <div>
      <div className="rentals-banner">
        <div className="rentals-banner-content">
          <div className="breadcrumb-container-rentals">
            <h1>Pocono Retreats Cabin INN</h1>
            <Breadcrumb className="breadcrumb-center-rentals">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Rentals</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <ProjectsGrid />
    </div>
  )
}

export default Rentals;
