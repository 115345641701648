import React from 'react'
import '../../Styles/page_contact.css';
import Button from 'react-bootstrap/Button';
import { Container, Form } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap'
//import NavigationBar from '../navigation/navbar'


function Contact() {
  return (
    <div>
      <div className="project-banner">
        <div className="contact-banner-content">
          <div className="breadcrumb-container">
            <h1>Contact Us Today!</h1>
            <Breadcrumb className="breadcrumb-center">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Contact</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="contact-section">
        <h1>Get in Touch</h1>
        <div className="contact-details">
          <div className="contact-address">
            <span className="icon-location">&#128205;</span>
            <p><b>Pocono Retreats Cabins INN</b><br />
              264 Lake Harmony Rd<br />
              Suite 2 Lake Harmony<br />
              PA 18624</p>
          </div>
          <div className="contact-divider"></div>
          <div className="contact-info">
            <p>
              <span className="icon-phone">&#128222;</span>
              (272) 999-1602
            </p>
            <p>
              <span className="icon-email">&#9993;</span>
              <a href="mailto:info@poconocabinrentals.com">info@poconocabinrentals.com</a>
            </p>
          </div>
        </div>
      </div>
      <Container className="mt-5 position-relative">
        <h1 className="text-primary">BooK Now!</h1>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter your email" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="tel" placeholder="Enter your phone number" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
          </Form.Group>

          <Button variant="primary" type="submit" className="mb-5">
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default Contact;
