import NavigationBar from './Componets/navigation/navbar';
import './App.css';
//import Box from './Componets/Box';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
//import CarouselFadeExample from './Componets/Carousel';
import Footer from './Componets/Footer';
//import Button from 'react-bootstrap/Button';
//import ProjectsGrid from './Componets/ProjectsGrid';
//import Testimonials from './Componets/Testimonials';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Home from './Componets/pages/Home';
import About from './Componets/pages/About';

import Project from './Componets/pages/Project';
import Rentals from './Componets/pages/Rentals';
import Contact from './Componets/pages/Contact';
import QuoteForm from './Componets/QuoteForm'; // Make sure the path is correct
import Showcase1 from './Componets/pages/Showcase1'; // Make sure the path is correct
import Showcase2 from './Componets/pages/Showcase2'; // Make sure the path is correct


function Layout() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Rentals" element={<Rentals />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/QuoteForm" element={<QuoteForm />} />
          <Route path="/Projects/Showcase1" element={<Showcase1 />} /> {/* Add this line */}
          <Route path="/Projects/Showcase2" element={<Showcase2 />} /> {/* Add this line */}
          <Route path="/Rentals/Showcase1" element={<Showcase1 />} /> {/* Add this line */}
          <Route path="/Rentals/Showcase2" element={<Showcase2 />} /> {/* Add this line */}
        </Routes>
        <Outlet />
        <Footer />
      </Router>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
