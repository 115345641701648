import React from 'react';
import { Card, Button, CardGroup, Col } from 'react-bootstrap';
import '../Styles/ProjectsGrid.css'; // Make sure the path is correct
import Image1 from '../Images/PR_US209_1.jpg'; // Import your images
import Image2 from '../Images/PR_Milford_1.jpg'; // Replace with your image path

function ProjectsGrid() {
  const projects = [
    {
      title: '2525 US-209, Sciota, PA 18354',
      description: 'Presenting a captivating Cabin INN situated in the vibrant Sciota. A magic place for a family or hosting guests. A place where you can enjoy the beauty of nature and the comfort of a modern home.',
      imageUrl: Image1, // Use the imported image
      link: '/Projects/Showcase1' // Replace with your link
    },
    {
      title: '5951 MILFORD ROAD, East Stroudsburg, PA 18302',
      description: 'Discover an alluring Cabin INN nestled in the dynamic East Stroudsburg. Experience the perfect blend of natural beauty and the amenities of modern living in this unique setting.',
      imageUrl: Image2, // Use the imported image
      link: '/Projects/Showcase2' // Replace with your link
    },
    // ... Add more projects as needed
  ];

  return (
    <CardGroup className="project-card-group">
      {projects.map((project, index) => (
        <Col xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
          <Card className="project-card">
            <Card.Img variant="top" src={project.imageUrl} />
            <Card.Body>
              <Card.Title>{project.title}</Card.Title>
              <Card.Text>{project.description}</Card.Text>
              <Button variant="warning" href={project.link}>Book Now →</Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </CardGroup>
  );
}

export default ProjectsGrid;


//src={require('../Images/Skct_store_modeling.jpg')}