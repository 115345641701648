import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import '../Styles/carousel.css';
import { Link } from 'react-router-dom';
// Importing icons from react-icons
//import ExampleCarouselImage from 'components/ExampleCarouselImage';

function CarouselFadeExample() {
  return (
    <Carousel fade nextIcon={<FaArrowRight size={30} color="white" />} prevIcon={<FaArrowLeft size={30} color="white" />}>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={require('../Images/PR_home3.png')}
          alt='First slide'
        />
        <Carousel.Caption>
          <h3 className="animate__animated animate__fadeInLeft">Pocono Cabin INN</h3>
          <p className="animate__animated animate__fadeInRight">Find balance and serenity</p>
          <Link to="/QuoteForm">
            <Button className="animate__animated animate__fadeInUp Car_secimg">Book Now</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={require('../Images/PR_home2.jpg')}
          alt='Second slide'
        />
        <Carousel.Caption>
          <h3 className="animate__animated animate__fadeInLeft">Discover Your Perfect Getaway</h3>
          <p className="animate__animated animate__fadeInRight">Your Next Stay Clicks Away</p>
          <Link to="/QuoteForm">
            <Button className="animate__animated animate__fadeInUp Car_secimg" variant="primary">Book Now</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={require('../Images/PR_home1.jpg')}
          alt='Third slide'
        />
        <Carousel.Caption>
          <h2 className="animate__animated animate__fadeInLeft">Pocono Cabin INN</h2>
          <p className="animate__animated animate__fadeInRight">Escape to tranquility</p>
          <Link to="/QuoteForm">
            <Button className="animate__animated animate__fadeInUp Car_secimg" variant="primary">Book Now</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeExample;


//After copilot code modification.

//<h3 className="animate__animated animate__fadeInLeft">First slide label</h3>
//<p className="animate__animated animate__fadeInRight">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
//<Button className="animate__animated animate__fadeInUp">Learn More</Button>



//Before copilot code modification.

//<h3>SKYLINECITY NY CONSTRUCTIONS</h3>
//<p>Driven to satisfy and exceed our clients' expectations</p>
//<Button className='Car_secimg' variant="primary">Request Quote</Button>