import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import PhoneIcon from '@mui/icons-material/Phone'
import MailIcon from '@mui/icons-material/Mail'
import '../Styles/Header.css'

function Header(){
  const instagramLink = 'https://www.instagram.com/skylinecity_team/'

  return (
    <header className="header header-component">
      <div className="header__logo">
      </div>
      <div className="header__social">
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <InstagramIcon />
        </a>
      </div>
      <div className="header__separator" />
      <div className="header__contact">
        <PhoneIcon style={{ color: 'gold' }} />
        <p> Call +1(917)-561-8956</p>
      </div>
      <div className="header__contact">
        <MailIcon style={{ color: 'gold' }} />
        <p>info@poconoretreats.com</p>
      </div>
    </header>
  )
}

export default Header;

