import React from 'react';
import Button from 'react-bootstrap/Button';
import { Container, Form } from 'react-bootstrap';

function QuoteForm() {
    return (
        <Container className="mt-5 position-relative">
            <div className="text-center" style={{ color: 'black' }}>
                <h3>Pocono Retreats Cabin INN</h3>
                <p>Embark on a serene escape to the heart of the Poconos with Pocono Retreats Cabin INN. Our cabins offer the perfect blend of rustic charm and modern comfort, nestled amidst the breathtaking natural beauty of the Pocono Mountains. Whether you're seeking a peaceful retreat, an adventure-filled getaway, or a cozy spot to unwind, we invite you to fill out the form below. Our team is dedicated to providing personalized recommendations and arranging your ideal cabin experience. Expect a prompt, detailed response within 24-48 hours to help you plan your unforgettable stay at Pocono Retreats Cabin INN.</p>
            </div>
            <h1 className="text-primary">Request a Quote</h1>
            <Form>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" placeholder="Enter your phone number" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
                </Form.Group>

                <Button variant="primary" type="submit" className="mb-5">
                    Submit
                </Button>
            </Form>
        </Container>
    );
}

export default QuoteForm;


