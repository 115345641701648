import React from 'react'
import '../../Styles/page_projects.css'
import { Breadcrumb } from 'react-bootstrap'
//import ProjectsGrid from '../ProjectsGrid'
//import NavigationBar from '../navigation/navbar'

function Project() {
  return (
    <div>
      <div className="project-banner">
        <div className="project-banner-content">
          <div className="breadcrumb-container">
            <h1>Skylinecity NY Construction's Project Portfolio</h1>
            <Breadcrumb className="breadcrumb-center">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Projects</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="project-pre-showcase">
        <p>OUR EXTENSIVE RESUME IS COMPRISED OF OVER 150+ PROJECTS COMPLETED</p>
        <h1>Our diverse project list includes commercial buildings and manufacturing structures</h1>
      </div>
      <div className="project-showcase">
        <div className="project-showcase-content">
          <h1>Project Showcase</h1>
          <p>Here are some of our recent projects</p>
        </div>
      </div>
    </div>
  )
}

export default Project;
