import React from 'react'
import { CCarousel, CCarouselItem, CImage } from '@coreui/react'
import { CCard, CCardBody, CCardText, CCardTitle, CCardFooter, CButton } from '@coreui/react'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Breadcrumb } from 'react-bootstrap'
import '../../Styles/showcase2.css'

import Image1 from '../../Images/Property2_MilfordRd/PR_MilfordRd (1).jpg'
import Image2 from '../../Images/Property2_MilfordRd/PR_MilfordRd (2).jpg'
import Image3 from '../../Images/Property2_MilfordRd/PR_MilfordRd (3).jpg'
import Image4 from '../../Images/Property2_MilfordRd/PR_MilfordRd (4).jpg'
import Image5 from '../../Images/Property2_MilfordRd/PR_MilfordRd (5).jpg'
import Image6 from '../../Images/Property2_MilfordRd/PR_MilfordRd (6).jpg'
import Image7 from '../../Images/Property2_MilfordRd/PR_MilfordRd (7).jpg'
import Image8 from '../../Images/Property2_MilfordRd/PR_MilfordRd (8).jpg'
import Image9 from '../../Images/Property2_MilfordRd/PR_MilfordRd (9).jpg'
import Image10 from '../../Images/Property2_MilfordRd/PR_MilfordRd (10).jpg'
import Image11 from '../../Images/Property2_MilfordRd/PR_MilfordRd (11).jpg'
import Image12 from '../../Images/Property2_MilfordRd/PR_MilfordRd (12).jpg'
import Image13 from '../../Images/Property2_MilfordRd/PR_MilfordRd (13).jpg'
import Image14 from '../../Images/Property2_MilfordRd/PR_MilfordRd (14).jpg'
import Image15 from '../../Images/Property2_MilfordRd/PR_MilfordRd (15).jpg'
import Image16 from '../../Images/Property2_MilfordRd/PR_MilfordRd (16).jpg'
import Image17 from '../../Images/Property2_MilfordRd/PR_MilfordRd (17).jpg'
import Image18 from '../../Images/Property2_MilfordRd/PR_MilfordRd (18).jpg'
import Image19 from '../../Images/Property2_MilfordRd/PR_MilfordRd (19).jpg'
import Image20 from '../../Images/Property2_MilfordRd/PR_MilfordRd (20).jpg'
import Image21 from '../../Images/Property2_MilfordRd/PR_MilfordRd (21).jpg'
import Image22 from '../../Images/Property2_MilfordRd/PR_MilfordRd (22).jpg'
import Image23 from '../../Images/Property2_MilfordRd/PR_MilfordRd (23).jpg'
import Image24 from '../../Images/Property2_MilfordRd/PR_MilfordRd (24).jpg'
import Image25 from '../../Images/Property2_MilfordRd/PR_MilfordRd (25).jpg'
import Image26 from '../../Images/Property2_MilfordRd/PR_MilfordRd (26).jpg'
import Image27 from '../../Images/Property2_MilfordRd/PR_MilfordRd (27).jpg'
import Image28 from '../../Images/Property2_MilfordRd/PR_MilfordRd (28).jpg'
import Image29 from '../../Images/Property2_MilfordRd/PR_MilfordRd (29).jpg'
import Image30 from '../../Images/Property2_MilfordRd/PR_MilfordRd (30).jpg'
import Image31 from '../../Images/Property2_MilfordRd/PR_MilfordRd (31).jpg'
import Image32 from '../../Images/Property2_MilfordRd/PR_MilfordRd (32).jpg'
import Image33 from '../../Images/Property2_MilfordRd/PR_MilfordRd (33).jpg'
import Image34 from '../../Images/Property2_MilfordRd/PR_MilfordRd (34).jpg'
import Image35 from '../../Images/Property2_MilfordRd/PR_MilfordRd (35).jpg'
import Image36 from '../../Images/Property2_MilfordRd/PR_MilfordRd (36).jpg'
import Image37 from '../../Images/Property2_MilfordRd/PR_MilfordRd (37).jpg'
import Image38 from '../../Images/Property2_MilfordRd/PR_MilfordRd (38).jpg'
import Image39 from '../../Images/Property2_MilfordRd/PR_MilfordRd (39).jpg'
import Image40 from '../../Images/Property2_MilfordRd/PR_MilfordRd (40).jpg'
import Image41 from '../../Images/Property2_MilfordRd/PR_MilfordRd (41).jpg'
import Image42 from '../../Images/Property2_MilfordRd/PR_MilfordRd (42).jpg'
import Image43 from '../../Images/Property2_MilfordRd/PR_MilfordRd (43).jpg'
import Image44 from '../../Images/Property2_MilfordRd/PR_MilfordRd (44).jpg'
import Image45 from '../../Images/Property2_MilfordRd/PR_MilfordRd (45).jpg'
import Image46 from '../../Images/Property2_MilfordRd/PR_MilfordRd (46).jpg'
import Image47 from '../../Images/Property2_MilfordRd/PR_MilfordRd (47).jpg'
import Image48 from '../../Images/Property2_MilfordRd/PR_MilfordRd (48).jpg'
import Image49 from '../../Images/Property2_MilfordRd/PR_MilfordRd (49).jpg'
import Image50 from '../../Images/Property2_MilfordRd/PR_MilfordRd (50).jpg'
import Image51 from '../../Images/Property2_MilfordRd/PR_MilfordRd (51).jpg'
import Image52 from '../../Images/Property2_MilfordRd/PR_MilfordRd (52).jpg'
import Image53 from '../../Images/Property2_MilfordRd/PR_MilfordRd (53).jpg'
import Image54 from '../../Images/Property2_MilfordRd/PR_MilfordRd (54).jpg'
import Image55 from '../../Images/Property2_MilfordRd/PR_MilfordRd (55).jpg'
import Image56 from '../../Images/Property2_MilfordRd/PR_MilfordRd (56).jpg'
import Image57 from '../../Images/Property2_MilfordRd/PR_MilfordRd (57).jpg'
import Image58 from '../../Images/Property2_MilfordRd/PR_MilfordRd (58).jpg'
import Image59 from '../../Images/Property2_MilfordRd/PR_MilfordRd (59).jpg'
import Image60 from '../../Images/Property2_MilfordRd/PR_MilfordRd (60).jpg'
import Image61 from '../../Images/Property2_MilfordRd/PR_MilfordRd (61).jpg'
import Image62 from '../../Images/Property2_MilfordRd/PR_MilfordRd (62).jpg'
import Image63 from '../../Images/Property2_MilfordRd/PR_MilfordRd (63).jpg'
import Image64 from '../../Images/Property2_MilfordRd/PR_MilfordRd (64).jpg'
import Image65 from '../../Images/Property2_MilfordRd/PR_MilfordRd (65).jpg'
import Image66 from '../../Images/Property2_MilfordRd/PR_MilfordRd (66).jpg'
import Image67 from '../../Images/Property2_MilfordRd/PR_MilfordRd (67).jpg'
import Image68 from '../../Images/Property2_MilfordRd/PR_MilfordRd (68).jpg'
import Image69 from '../../Images/Property2_MilfordRd/PR_MilfordRd (69).jpg'
import Image70 from '../../Images/Property2_MilfordRd/PR_MilfordRd (70).jpg'
import Image71 from '../../Images/Property2_MilfordRd/PR_MilfordRd (71).jpg'
import Image72 from '../../Images/Property2_MilfordRd/PR_MilfordRd (72).jpg'
import Image73 from '../../Images/Property2_MilfordRd/PR_MilfordRd (73).jpg'
import Image74 from '../../Images/Property2_MilfordRd/PR_MilfordRd (74).jpg'
import Image75 from '../../Images/Property2_MilfordRd/PR_MilfordRd (75).jpg'
import Image76 from '../../Images/Property2_MilfordRd/PR_MilfordRd (76).jpg'
import Image77 from '../../Images/Property2_MilfordRd/PR_MilfordRd (77).jpg'
import Image78 from '../../Images/Property2_MilfordRd/PR_MilfordRd (78).jpg'
import Image79 from '../../Images/Property2_MilfordRd/PR_MilfordRd (79).jpg'
import Image80 from '../../Images/Property2_MilfordRd/PR_MilfordRd (80).jpg'
import Image81 from '../../Images/Property2_MilfordRd/PR_MilfordRd (81).jpg'
import Image82 from '../../Images/Property2_MilfordRd/PR_MilfordRd (82).jpg'
import Image83 from '../../Images/Property2_MilfordRd/PR_MilfordRd (83).jpg'
import Image84 from '../../Images/Property2_MilfordRd/PR_MilfordRd (84).jpg'
import Image85 from '../../Images/Property2_MilfordRd/PR_MilfordRd (85).jpg'
import Image86 from '../../Images/Property2_MilfordRd/PR_MilfordRd (86).jpg'
import Image87 from '../../Images/Property2_MilfordRd/PR_MilfordRd (87).jpg'
import Image88 from '../../Images/Property2_MilfordRd/PR_MilfordRd (88).jpg'
import Image89 from '../../Images/Property2_MilfordRd/PR_MilfordRd (89).jpg'
import Image90 from '../../Images/Property2_MilfordRd/PR_MilfordRd (90).jpg'
import Image91 from '../../Images/Property2_MilfordRd/PR_MilfordRd (91).jpg'
import Image92 from '../../Images/Property2_MilfordRd/PR_MilfordRd (92).jpg'
import Image93 from '../../Images/Property2_MilfordRd/PR_MilfordRd (93).jpg'
import Image94 from '../../Images/Property2_MilfordRd/PR_MilfordRd (94).jpg'
import Image95 from '../../Images/Property2_MilfordRd/PR_MilfordRd (95).jpg'
import Image96 from '../../Images/Property2_MilfordRd/PR_MilfordRd (96).jpg'
import Image97 from '../../Images/Property2_MilfordRd/PR_MilfordRd (97).jpg'
import Image98 from '../../Images/Property2_MilfordRd/PR_MilfordRd (98).jpg'
import Image99 from '../../Images/Property2_MilfordRd/PR_MilfordRd (99).jpg'
import Image100 from '../../Images/Property2_MilfordRd/PR_MilfordRd (100).jpg'
import Image101 from '../../Images/Property2_MilfordRd/PR_MilfordRd (101).jpg'
import Image102 from '../../Images/Property2_MilfordRd/PR_MilfordRd (102).jpg'
import Image103 from '../../Images/Property2_MilfordRd/PR_MilfordRd (103).jpg'
import Image104 from '../../Images/Property2_MilfordRd/PR_MilfordRd (104).jpg'
import Image105 from '../../Images/Property2_MilfordRd/PR_MilfordRd (105).jpg'
import Image106 from '../../Images/Property2_MilfordRd/PR_MilfordRd (106).jpg'
import Image107 from '../../Images/Property2_MilfordRd/PR_MilfordRd (107).jpg'
import Image108 from '../../Images/Property2_MilfordRd/PR_MilfordRd (108).jpg'
import Image109 from '../../Images/Property2_MilfordRd/PR_MilfordRd (109).jpg'
import Image110 from '../../Images/Property2_MilfordRd/PR_MilfordRd (110).jpg'
import Image111 from '../../Images/Property2_MilfordRd/PR_MilfordRd (111).jpg'
import Image112 from '../../Images/Property2_MilfordRd/PR_MilfordRd (112).jpg'
import Image113 from '../../Images/Property2_MilfordRd/PR_MilfordRd (113).jpg'
import Image114 from '../../Images/Property2_MilfordRd/PR_MilfordRd (114).jpg'
import Image115 from '../../Images/Property2_MilfordRd/PR_MilfordRd (115).jpg'
import Image116 from '../../Images/Property2_MilfordRd/PR_MilfordRd (116).jpg'
import Image117 from '../../Images/Property2_MilfordRd/PR_MilfordRd (117).jpg'
import Image118 from '../../Images/Property2_MilfordRd/PR_MilfordRd (118).jpg'
import Image119 from '../../Images/Property2_MilfordRd/PR_MilfordRd (119).jpg'
import Image120 from '../../Images/Property2_MilfordRd/PR_MilfordRd (120).jpg'
import Image121 from '../../Images/Property2_MilfordRd/PR_MilfordRd (121).jpg'
import Image122 from '../../Images/Property2_MilfordRd/PR_MilfordRd (122).jpg'
import Image123 from '../../Images/Property2_MilfordRd/PR_MilfordRd (123).jpg'
import Image124 from '../../Images/Property2_MilfordRd/PR_MilfordRd (124).jpg'
import Image125 from '../../Images/Property2_MilfordRd/PR_MilfordRd (125).jpg'
import Image126 from '../../Images/Property2_MilfordRd/PR_MilfordRd (126).jpg'
import Image127 from '../../Images/Property2_MilfordRd/PR_MilfordRd (127).jpg'
import Image128 from '../../Images/Property2_MilfordRd/PR_MilfordRd (128).jpg'
import Image129 from '../../Images/Property2_MilfordRd/PR_MilfordRd (129).jpg'
import Image130 from '../../Images/Property2_MilfordRd/PR_MilfordRd (130).jpg'
import Image131 from '../../Images/Property2_MilfordRd/PR_MilfordRd (131).jpg'
import Image132 from '../../Images/Property2_MilfordRd/PR_MilfordRd (132).jpg'
import Image133 from '../../Images/Property2_MilfordRd/PR_MilfordRd (133).jpg'
import Image134 from '../../Images/Property2_MilfordRd/PR_MilfordRd (134).jpg'
import Image135 from '../../Images/Property2_MilfordRd/PR_MilfordRd (135).jpg'
import Image136 from '../../Images/Property2_MilfordRd/PR_MilfordRd (136).jpg'
import Image137 from '../../Images/Property2_MilfordRd/PR_MilfordRd (137).jpg'

const images = [
  {
    original: Image1,
  },
  {
    original: Image2,
  },
  {
    original: Image3,
  },
  {
    original: Image4,
  },
  {
    original: Image5,
  },
  {
    original: Image6,
  },
  {
    original: Image7,
  },
  {
    original: Image8,
  },
  {
    original: Image9,
  },
  {
    original: Image10,
  },
  {
    original: Image11,
  },
  {
    original: Image12,
  },
  {
    original: Image13,
  },
  {
    original: Image14,
  },
  {
    original: Image15,
  },
  {
    original: Image16,
  },
  {
    original: Image17,
  },
  {
    original: Image18,
  },
  {
    original: Image19,
  },
  {
    original: Image20,
  },
  {
    original: Image21,
  },
  {
    original: Image22,
  },
  {
    original: Image23,
  },
  {
    original: Image24,
  },
  {
    original: Image25,
  },
  {
    original: Image26,
  },
  {
    original: Image27,
  },
  {
    original: Image28,
  },
  {
    original: Image29,
  },
  {
    original: Image30,
  },
  {
    original: Image31,
  },
  {
    original: Image32,
  },
  {
    original: Image33,
  },
  {
    original: Image34,
  },
  {
    original: Image35,
  },
  {
    original: Image36,
  },
  {
    original: Image37,
  },
  {
    original: Image38,
  },
  {
    original: Image39,
  },
  {
    original: Image40,
  },
  {
    original: Image41,
  },
  {
    original: Image42,
  },
  {
    original: Image43,
  },
  {
    original: Image44,
  },
  {
    original: Image45,
  },
  {
    original: Image46,
  },
  {
    original: Image47,
  },
  {
    original: Image48,
  },
  {
    original: Image49,
  },
  {
    original: Image50,
  },
  {
    original: Image51,
  },
  {
    original: Image52,
  },
  {
    original: Image53,
  },
  {
    original: Image54,
  },
  {
    original: Image55,
  },
  {
    original: Image56,
  },
  {
    original: Image57,
  },
  {
    original: Image58,
  },
  {
    original: Image59,
  },
  {
    original: Image60,
  },
  {
    original: Image61,
  },
  {
    original: Image62,
  },
  {
    original: Image63,
  },
  {
    original: Image64,
  },
  {
    original: Image65,
  },
  {
    original: Image66,
  },
  {
    original: Image67,
  },
  {
    original: Image68,
  },
  {
    original: Image69,
  },
  {
    original: Image70,
  },
  {
    original: Image71,
  },
  {
    original: Image72,
  },
  {
    original: Image73,
  },
  {
    original: Image74,
  },
  {
    original: Image75,
  },
  {
    original: Image76,
  },
  {
    original: Image77,
  },
  {
    original: Image78,
  },
  {
    original: Image79,
  },
  {
    original: Image80,
  },
  {
    original: Image81,
  },
  {
    original: Image82,
  },
  {
    original: Image83,
  },
  {
    original: Image84,
  },
  {
    original: Image85,
  },
  {
    original: Image86,
  },
  {
    original: Image87,
  },
  {
    original: Image88,
  },
  {
    original: Image89,
  },
  {
    original: Image90,
  },
  {
    original: Image91,
  },
  {
    original: Image92,
  },
  {
    original: Image93,
  },
  {
    original: Image94,
  },
  {
    original: Image95,
  },
  {
    original: Image96,
  },
  {
    original: Image97,
  },
  {
    original: Image98,
  },
  {
    original: Image99,
  },
  {
    original: Image100,
  },
  {
    original: Image101,
  },
  {
    original: Image102,
  },
  {
    original: Image103,
  },
  {
    original: Image104,
  },
  {
    original: Image105,
  },
  {
    original: Image106,
  },
  {
    original: Image107,
  },
  {
    original: Image108,
  },
  {
    original: Image109,
  },
  {
    original: Image110,
  },
  {
    original: Image111,
  },
  {
    original: Image112,
  },
  {
    original: Image113,
  },
  {
    original: Image114,
  },
  {
    original: Image115,
  },
  {
    original: Image116,
  },
  {
    original: Image117,
  },
  {
    original: Image118,
  },
  {
    original: Image119,
  },
  {
    original: Image120,
  },
  {
    original: Image121,
  },
  {
    original: Image122,
  },
  {
    original: Image123,
  },
  {
    original: Image124,
  },
  {
    original: Image125,
  },
  {
    original: Image126,
  },
  {
    original: Image127,
  },
  {
    original: Image128,
  },
  {
    original: Image129,
  },
  {
    original: Image130,
  },
  {
    original: Image131,
  },
  {
    original: Image132,
  },
  {
    original: Image133,
  },
  {
    original: Image134,
  },
  {
    original: Image135,
  },
  {
    original: Image136,
  },
  {
    original: Image137,
  }
  // Add more images here
]

function Showcase2() {
  const instagramLink = 'https://www.instagram.com/skylinecity_team/'

  return (
    <>
      <div className="rentals-banner-milford">
        <div className="rentals-banner-content-milford">
          <div className="breadcrumb-container">
            <h1>East Stroudsburg</h1>
            <Breadcrumb className="breadcrumb-center">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href='/Rentals'>Rentals</Breadcrumb.Item>
              <Breadcrumb.Item active>Showcase 2</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '50px' }}>
        <CCarousel controls className="w-80">
          {images.map((image, index) => (
            <CCarouselItem key={index}>
              <CImage className="d-block" src={image.original} alt={`slide ${index + 1}`} style={{ objectFit: 'contain', width: '100%' }} />
            </CCarouselItem>
          ))}
        </CCarousel>
        <CCard style={{ width: '500px', margin: '30px', background: 'rgb(245, 245, 245)', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} className="bg-white text-black">
          <CCardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CCardText>
                <strong style={{ display: 'block', marginBottom: '10px' }}>Type</strong>
                <strong style={{ display: 'block', marginBottom: '10px' }}>Year</strong>
                <strong style={{ display: 'block', marginBottom: '5px' }}>Category</strong>
              </CCardText>
              <CCardText>
                <span style={{ display: 'block', marginBottom: '10px' }}>Commercial</span>
                <span style={{ display: 'block', marginBottom: '10px' }}>2024</span>
                <span style={{ display: 'block', marginBottom: '5px' }}>Renovation</span>
              </CCardText>
            </div>
          </CCardBody>
          <hr />
          <CCardBody>
            <CCardTitle>5951 MILFORD ROAD, East Stroudsburg,PA</CCardTitle>
            <CCardText>
              The property at 5951 Milford Road in East Stroudsburg, PA, is a multi-zoned location featuring new windows, a recently painted interior, and a repaired chimney. This versatile property is ideal for those seeking a residential space with potential income from a basement apartment or for business use. With no HOA restrictions and situated on 5 acres, it offers numerous possibilities. Currently used as a residential property with a separate apartment, it presents a great opportunity for a variety of applications. A showing is recommended to fully appreciate its potential.
            </CCardText>
          </CCardBody>
          <CCardFooter>
            <a href={instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <CButton href="#" color="light" block>Learn More</CButton>
          </CCardFooter>
        </CCard>
      </div>
    </>
  )
}

export default Showcase2;

