import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import '../Styles/Testimonials.css'; // Make sure the path is correct

const testimonials = [
  {
    name: 'Ernest Smith',
    status: 'Guest',
    quote: 'Best getaway experience I have had. The cabin in the Poconos was not only serene and beautiful, but the amenities were top-notch. Truly a memorable stay. Highly recommend.',
    rating: 5,
  },
  {
    name: 'Thomas Newton',
    status: 'Guest',
    quote: 'The cabin was the perfect blend of rustic charm and modern convenience. The booking process was seamless, and the stay was beyond comfortable. Its a great place to unwind and reconnect with nature.',
    rating: 5,
  },
  {
    name: 'Monica Rodriguez',
    status: 'Guest',
    quote: 'Choosing a cabin in the Poconos was the best decision for our family vacation. The scenic beauty and the cozy cabin made our trip unforgettable. Excellent service and facilities. Will be returning soon!',
    rating: 5,
  }
];

const Testimonials = () => {
  return (
    <Container>
      <Row>
        {testimonials.map((testimonial, index) => (
          <Col md={4} key={index} className="mb-3">
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Title>{testimonial.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{testimonial.status}</Card.Subtitle>
                <Card.Text>
                  <i className="fas fa-quote-left"></i> {/* Font Awesome quote icon */}
                  {testimonial.quote}
                  <div className="rating">
                    {'★'.repeat(testimonial.rating)}
                    {'☆'.repeat(5 - testimonial.rating)}
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Testimonials;
