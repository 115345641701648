import React from 'react'
//import NavigationBar from '../navigation/navbar'
import CarouselFadeExample from '../Carousel';
import Button from 'react-bootstrap/Button';
import ProjectsGrid from '../ProjectsGrid';
import Testimonials from '../Testimonials';
import '../../Styles/page_home.css';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function Home() {
  return (
    <>  {/* // This is a fragment tag to embody multiple div tags inside of it */}
      <div>
        <CarouselFadeExample />
      </div>
      <div className="intro-text">
        <p>EXCLUSIVE CABIN INN RENTALS IN THE POCONOS</p>
        <h1>Discover the Perfect Blend of Nature's Serenity and Modern Comfort</h1>
      </div>
      <div className="accordion-container">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What unique features does the Pocono Cabin INN offer?</Accordion.Header>
            <Accordion.Body>
              Our Poconos Cabin INN is designed to provide an exceptional retreat experience. Nestled in the heart of the Poconos, this cabin boasts a blend of rustic charm and modern amenities. It features cozy bedrooms, a fully equipped kitchen, and a spacious living area, perfect for family gatherings or a quiet getaway. The scenic surroundings offer an array of outdoor activities, making it an ideal choice for nature enthusiasts and those seeking a peaceful escape.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>How does the cabin ensure guest comfort and safety?</Accordion.Header>
            <Accordion.Body>
              Guest comfort and safety are paramount at our Poconos Cabin INN. The cabin is regularly maintained and inspected to uphold the highest standards of cleanliness and safety. It is equipped with modern safety features, including fire extinguishers, smoke detectors, and a first-aid kit. Our staff is trained in hospitality and safety protocols, ensuring a secure and comfortable stay for all our guests.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Can the cabin accommodate special events or group gatherings?</Accordion.Header>
            <Accordion.Body>
              Absolutely! Our Poconos Cabin INN is the perfect venue for special events and group gatherings. With its spacious layout and picturesque setting, it provides an ideal backdrop for events like family reunions, corporate retreats, and intimate weddings. We offer customizable packages to suit various needs and ensure that your event is memorable and seamlessly organized.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>What are the nearby attractions and activities?</Accordion.Header>
            <Accordion.Body>
              The Poconos region is renowned for its natural beauty and array of attractions. Guests at our Cabin INN can enjoy activities like hiking, fishing, skiing, and kayaking. Nearby attractions include state parks, scenic waterfalls, and historic sites. Whether you're looking for adventure or relaxation, the Poconos has something to offer for everyone.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>How do we book a stay and what are the reservation policies?</Accordion.Header>
            <Accordion.Body>
              Booking a stay at our Poconos Cabin INN is simple and convenient. Reservations can be made directly through our website or by contacting our reservation team. Our policies include flexible booking options, a clear cancellation policy, and details on check-in/check-out procedures. For any specific requests or inquiries, our dedicated staff is always available to assist you.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="content-with-button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '20px', marginLeft: '20px' }}>
        <div className="intro-text">
          <p className="small-text intro-text">EXPLORE OUR HANDPICKED SELECTION OF LUXURY CABINS IN THE POCONOS</p>
          <h2 className="intro-text">Discover Your Perfect Getaway Amongst Our Diverse Range of Cabin Rentals</h2>
        </div>
        <Link to="/Rentals">
          <Button variant="warning" className="projects-button">All Rentals→</Button>
        </Link>
      </div>
      <div>
        <ProjectsGrid />
      </div>
      <div className="intro-text">
        <p>What our clients are saying about our company and properties</p>
        <h1>Our clients are more than clients. We’re partners — we start at the vision and take every step together</h1>
      </div>
      <div>
        <Testimonials />
      </div>
    </>
  );
}

export default Home;
