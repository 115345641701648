import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import '../../Styles/page_about.css';
import Button from 'react-bootstrap/Button';


function AboutCard() {
  return (
    <div className="about-card">
      <img src={require('../../Images/PR_about_IMG.jpg')} alt="About Us" className="about-image" />
      <div className="about-content">
        <h2>About Us</h2>
        <p>
          At our retreats, we offer a wide range of unique experiences that cater to your desires. Our team of experts is dedicated to providing you with a personalized getaway that will leave you feeling refreshed and rejuvenated.
        </p>
        <p>
          From luxurious spa treatments to adventurous outdoor activities, we have something for everyone. Our retreats are designed to help you disconnect from the hustle and bustle of everyday life and reconnect with yourself and nature.
        </p>
        <p>
          Cabin rental pedigree is something we also take very seriously to ensure experience is maximized. We only accept cabins with great locations, incredible amenities, and renovated interiors. It is important to have a quality product that delivers memories to our guests.
        </p>
        <p>
        We sincerely hope you enjoy your stay with <a href="/Home"><b>Pocono Retreats</b></a> and thank you for booking with us!
        </p>
        <Button variant="warning" href="/Contact">Contact Us!</Button>
      </div>
    </div>
  )
}




function About() {
  return (
    <div>
      <div className="about-banner">
        <div className="about-banner-content">
        <div className="breadcrumb-container">
          <h1>Experience Unparalleled Retreats with Our Exclusive Direct Booking</h1>
          <Breadcrumb className="breadcrumb-center">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>About</Breadcrumb.Item>
            </Breadcrumb>
        </div>
        </div>
      </div>
      <div className="intro-text">
        <p>DISCOVER OVER 150+ UNIQUE RETREAT EXPERIENCES</p>
        <h1>Indulge in Personalized Getaways Tailored to Your Desires</h1>
      </div>
      <AboutCard />
    </div>
  );
}

export default About;

