import React from 'react'
import { CCarousel, CCarouselItem, CImage } from '@coreui/react'
import { CCard, CCardBody, CCardText, CCardTitle, CCardFooter, CButton } from '@coreui/react'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Breadcrumb } from 'react-bootstrap'
import '../../Styles/showcase1.css'



import Image1 from '../../Images/Property1_US209/PR_US209 (1).jpg'
import Image2 from '../../Images/Property1_US209/PR_US209 (2).jpg'
import Image3 from '../../Images/Property1_US209/PR_US209 (3).jpg'
import Image4 from '../../Images/Property1_US209/PR_US209 (4).jpg'
import Image5 from '../../Images/Property1_US209/PR_US209 (5).jpg'
import Image6 from '../../Images/Property1_US209/PR_US209 (6).jpg'
import Image7 from '../../Images/Property1_US209/PR_US209 (7).jpg'
import Image8 from '../../Images/Property1_US209/PR_US209 (8).jpg'
import Image9 from '../../Images/Property1_US209/PR_US209 (9).jpg'
import Image10 from '../../Images/Property1_US209/PR_US209 (10).jpg'
import Image11 from '../../Images/Property1_US209/PR_US209 (11).jpg'
import Image12 from '../../Images/Property1_US209/PR_US209 (12).jpg'
import Image13 from '../../Images/Property1_US209/PR_US209 (13).jpg'
import Image14 from '../../Images/Property1_US209/PR_US209 (14).jpg'
import Image15 from '../../Images/Property1_US209/PR_US209 (15).jpg'
import Image16 from '../../Images/Property1_US209/PR_US209 (16).jpg'
import Image17 from '../../Images/Property1_US209/PR_US209 (17).jpg'
import Image18 from '../../Images/Property1_US209/PR_US209 (18).jpg'
import Image19 from '../../Images/Property1_US209/PR_US209 (19).jpg'
import Image20 from '../../Images/Property1_US209/PR_US209 (20).jpg'
import Image21 from '../../Images/Property1_US209/PR_US209 (21).jpg'
import Image22 from '../../Images/Property1_US209/PR_US209 (22).jpg'
import Image23 from '../../Images/Property1_US209/PR_US209 (23).jpg'
import Image24 from '../../Images/Property1_US209/PR_US209 (24).jpg'
import Image25 from '../../Images/Property1_US209/PR_US209 (25).jpg'
import Image26 from '../../Images/Property1_US209/PR_US209 (26).jpg'
import Image27 from '../../Images/Property1_US209/PR_US209 (27).jpg'
import Image28 from '../../Images/Property1_US209/PR_US209 (28).jpg'
import Image29 from '../../Images/Property1_US209/PR_US209 (29).jpg'
import Image30 from '../../Images/Property1_US209/PR_US209 (30).jpg'
import Image31 from '../../Images/Property1_US209/PR_US209 (31).jpg'
import Image32 from '../../Images/Property1_US209/PR_US209 (32).jpg'
import Image33 from '../../Images/Property1_US209/PR_US209 (33).jpg'
import Image34 from '../../Images/Property1_US209/PR_US209 (34).jpg'
import Image35 from '../../Images/Property1_US209/PR_US209 (35).jpg'
import Image36 from '../../Images/Property1_US209/PR_US209 (36).jpg'
import Image37 from '../../Images/Property1_US209/PR_US209 (37).jpg'
import Image38 from '../../Images/Property1_US209/PR_US209 (38).jpg'
import Image39 from '../../Images/Property1_US209/PR_US209 (39).jpg'
import Image40 from '../../Images/Property1_US209/PR_US209 (40).jpg'
import Image41 from '../../Images/Property1_US209/PR_US209 (41).jpg'
import Image42 from '../../Images/Property1_US209/PR_US209 (42).jpg'
import Image43 from '../../Images/Property1_US209/PR_US209 (43).jpg'
import Image44 from '../../Images/Property1_US209/PR_US209 (44).jpg'
import Image45 from '../../Images/Property1_US209/PR_US209 (45).jpg'
import Image46 from '../../Images/Property1_US209/PR_US209 (46).jpg'
import Image47 from '../../Images/Property1_US209/PR_US209 (47).jpg'
import Image48 from '../../Images/Property1_US209/PR_US209 (48).jpg'
import Image49 from '../../Images/Property1_US209/PR_US209 (49).jpg'
import Image50 from '../../Images/Property1_US209/PR_US209 (50).jpg'
import Image51 from '../../Images/Property1_US209/PR_US209 (51).jpg'
import Image52 from '../../Images/Property1_US209/PR_US209 (52).jpg'
import Image53 from '../../Images/Property1_US209/PR_US209 (53).jpg'
import Image54 from '../../Images/Property1_US209/PR_US209 (54).jpg'
import Image55 from '../../Images/Property1_US209/PR_US209 (55).jpg'
import Image56 from '../../Images/Property1_US209/PR_US209 (56).jpg'
import Image57 from '../../Images/Property1_US209/PR_US209 (57).jpg'
import Image58 from '../../Images/Property1_US209/PR_US209 (58).jpg'
import Image59 from '../../Images/Property1_US209/PR_US209 (59).jpg'
import Image60 from '../../Images/Property1_US209/PR_US209 (60).jpg'
import Image61 from '../../Images/Property1_US209/PR_US209 (61).jpg'
import Image62 from '../../Images/Property1_US209/PR_US209 (62).jpg'
import Image63 from '../../Images/Property1_US209/PR_US209 (63).jpg'
import Image64 from '../../Images/Property1_US209/PR_US209 (64).jpg'
import Image65 from '../../Images/Property1_US209/PR_US209 (65).jpg'


const images = [
  {
    original: Image1,
  },
  {
    original: Image2,
  },
  {
    original: Image3,
  },
  {
    original: Image4,
  },
  {
    original: Image5,
  },
  {
    original: Image6,
  },
  {
    original: Image7,
  },
  {
    original: Image8,
  },
  {
    original: Image9,
  },
  {
    original: Image10,
  },
  {
    original: Image11,
  },
  {
    original: Image12,
  },
  {
    original: Image13,
  },
  {
    original: Image14,
  },
  {
    original: Image15,
  },
  {
    original: Image16,
  },
  {
    original: Image17,
  },
  {
    original: Image18,
  },
  {
    original: Image19,
  },
  {
    original: Image20,
  },
  {
    original: Image21,
  },
  {
    original: Image22,
  },
  {
    original: Image23,
  },
  {
    original: Image24,
  },
  {
    original: Image25,
  },
  {
    original: Image26,
  },
  {
    original: Image27,
  },
  {
    original: Image28,
  },
  {
    original: Image29,
  },
  {
    original: Image30,
  },
  {
    original: Image31,
  },
  {
    original: Image32,
  },
  {
    original: Image33,
  },
  {
    original: Image34,
  },
  {
    original: Image35,
  },
  {
    original: Image36,
  },
  {
    original: Image37,
  },
  {
    original: Image38,
  },
  {
    original: Image39,
  },
  {
    original: Image40,
  },
  {
    original: Image41,
  },
  {
    original: Image42,
  },
  {
    original: Image43,
  },
  {
    original: Image44,
  },
  {
    original: Image45,
  },
  {
    original: Image46,
  },
  {
    original: Image47,
  },
  {
    original: Image48,
  },
  {
    original: Image49,
  },
  {
    original: Image50,
  },
  {
    original: Image51,
  },
  {
    original: Image52,
  },
  {
    original: Image53,
  },
  {
    original: Image54,
  },
  {
    original: Image55,
  },
  {
    original: Image56,
  },
  {
    original: Image57,
  },
  {
    original: Image58,
  },
  {
    original: Image59,
  },
  {
    original: Image60,
  },
  {
    original: Image61,
  },
  {
    original: Image62,
  },
  {
    original: Image63,
  },
  {
    original: Image64,
  },
  {
    original: Image65,
  }
  // Add more images here
]

function Showcase1() {
  const instagramLink = 'https://www.instagram.com/skylinecity_team/'

  return (
    <>
      <div className="rentals-banner-us209">
        <div className="rentals-banner-content-us209">
          <div className="breadcrumb-container">
            <h1>Sciota Pennsylvania</h1>
            <Breadcrumb className="breadcrumb-center">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href='/Rentals'>Rentals</Breadcrumb.Item>
              <Breadcrumb.Item active>Showcase 1</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '50px' }}>
        <CCarousel controls className="w-80">
          {images.map((image, index) => (
            <CCarouselItem key={index}>
              <CImage className="d-block" src={image.original} alt={`slide ${index + 1}`} style={{ objectFit: 'contain', width: '100%' }} />
            </CCarouselItem>
          ))}
        </CCarousel>
        <CCard style={{ width: '500px', margin: '30px', background: 'rgb(245, 245, 245)', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} className="bg-white text-black">
          <CCardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CCardText>
                <strong style={{ display: 'block', marginBottom: '10px' }}>Type</strong>
                <strong style={{ display: 'block', marginBottom: '10px' }}>Year</strong>
                <strong style={{ display: 'block', marginBottom: '5px' }}>Category</strong>
              </CCardText>
              <CCardText>
                <span style={{ display: 'block', marginBottom: '10px' }}>Commercial</span>
                <span style={{ display: 'block', marginBottom: '10px' }}>2024</span>
                <span style={{ display: 'block', marginBottom: '5px' }}>Renovation</span>
              </CCardText>
            </div>
          </CCardBody>
          <hr />
          <CCardBody>
            <CCardTitle>2525 US-209, Sciota, PA</CCardTitle>
            <CCardText>
              This unique property features a converted 5-bedroom, 2.5-bathroom barn on nearly 6 acres, offering both commercial and private opportunities. It includes a pond with a dock, vaulted ceilings with exposed original barnwood beams, a stone fireplace, slate and hardwood floors, and an efficiency area with a kitchenette. The property boasts spectacular views, a balcony, a 2-car attached garage, and a separate outbuilding. It offers a blend of country living with commercial zoning benefits, close to amenities. The property is sold as-is and must be seen in person to fully appreciate its character and potential.
            </CCardText>
          </CCardBody>
          <CCardFooter>
            <a href={instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <CButton href="#" color="light" block>Learn More</CButton>
          </CCardFooter>
        </CCard>
      </div>
    </>
  )
}

export default Showcase1

