import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../Styles/Footer.css'; // Adjust the path as necessary
import logo from '../Images/PR_logo.png'; // Make sure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faInstagram } from '@fortawesome/free-brands-svg-icons'
//import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram'
import Nav from 'react-bootstrap/Nav';

const Footer = () => {
  const instagramLink = 'https://www.instagram.com/skylinecity_team/'
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} md={3} className="footer-section">
            <img src={logo} alt='mylogo' width="80" height="80" className="d-inline-block align-top" />{' '}
            <p>Experience luxury and tranquility with Pocono Retreats. Our direct booking offers a seamless gateway to your perfect Pocono escape, where elegance meets nature.</p>
          </Col>
          <Col xs={6} md={3} className="footer-section">
            <h5>Navigation</h5>
            <ul>
              <Link to="/Home">
                <Nav.Link as="span" className="nav-link footer-link">
                  Home
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="/Rentals">
                <Nav.Link as="span" className="nav-link footer-link">
                  Rentals
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="/About">
                <Nav.Link as="span" className="nav-link footer-link">
                  About
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="contact">
                <Nav.Link as="span" className="nav-link footer-link">
                  Contact
                  <div className="underline"></div>
                </Nav.Link>
              </Link>
              <Link to="/QuoteForm">
                <Button className="footer-button">
                  Book Now
                  <div className="underline"></div>
                </Button>
              </Link>
            </ul>
          </Col>
          <Col xs={6} md={3} className="footer-section">
            <h5>Follow Us</h5>

            <div className="header__instagram">
              <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
            </div>
            {/* Social media icons or links */}
          </Col>
          <Col xs={12} md={3} className="footer-section">
            <h5>Contact Us</h5>
            <p>📞 +1(917)-561-8956</p>
            <p>📍 Appointment Only</p>
            <p>✉️ info@poconoretreats.com</p>
            <h5>Hours of Operation</h5>
            <p><FontAwesomeIcon icon={faClock} className="clock-icon" />Mon - Fri: 9AM - 5PM</p>
            <p><FontAwesomeIcon icon={faClock} className="clock-icon" />Sat - Sun: Appointment only</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;


//<FontAwesomeIcon icon={faFacebook} size="2x" className="social-icon" />